<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="600px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="180px"
      >
        <el-form-item label="工衣级别" prop="level">
          <el-input
            v-model="airForm.level"
            placeholder="请输入工衣级别"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="数量" prop="quantity">
          <el-input
            v-model="airForm.quantity"
            placeholder="请输入数量"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="编号" prop="serialNumber">
          <el-input
            v-model="airForm.serialNumber"
            placeholder="请输入编号"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="清洗日期" prop="cleaningDate">
          <el-date-picker
            v-model="airForm.cleaningDate"
            type="datetime"
            placeholder="选择清洗日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="airForm.remarks"
            placeholder="请输入备注"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="编号">
        <el-input
          v-model="searchForm.serialNumber"
          placeholder="请输入编号"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="cleaningDate" label="清洗日期" />
      <el-table-column prop="level" label="工衣级别" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="serialNumber" label="编号" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getClothesWashList,
  deleteClothesWashList,
  addClothesWashDetail,
  getClothesWashInfoDetail,
  updateClothesWashDetail,
} from "@/api/produce/produce.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      waterList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        serialNumber: "",
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      airDetailForm: {},
      productFormRules: {
        disinfectionObject: [
          {
            required: true,
            message: "时间名称不能为空",
            trigger: ["change","blur"],
          },
        ],
      },
      productFormRules2: {
        level: [
          {
            required: true,
            message: "工衣级别不能为空",
            trigger: ["change","blur"],
          },
        ],
        quantity: [
          {
            required: true,
            message: "数量不能为空",
            trigger: ["change","blur"],
          },
        ],
        serialNumber: [
          {
            required: true,
            message: "编号不能为空",
            trigger: ["change","blur"],
          },
        ],
        cleaningDate: [
          {
            required: true,
            message: "清洗日期不能为空",
            trigger: ["change","blur"],
          },
        ],
      },
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let waterList = await getClothesWashList(params);
    this.waterList = waterList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addClothesWashDetail(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            await updateClothesWashDetail(params);
          }
          this.waterList = await getClothesWashList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增工作服清洗记录";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteClothesWashList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.waterList = await getClothesWashList(this.searchForm);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "工作服清洗记录";
      this.handleType = "update";
      getClothesWashInfoDetail(row.id).then((res) => {
        this.airForm = { ...res };
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "工作服清洗记录";
      this.handleType = "detail";
      getClothesWashInfoDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log({ ...res });
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getClothesWashList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.waterList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getClothesWashList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getClothesWashList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
  },
  watch: {
    airDialogVisible(val){
      if(!val){
        this.airForm = {}
      }
    }
  },

};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
